html,
body {
  height: 100%;
  width: 100%;
  direction: rtl;
  font-family: Arial !important;
}
#root {
  height: 100%;
  width: 100%;
}
/* custom-audio.css */
audio::-webkit-media-controls-panel {
  display: flex !important;
  align-items: center;
}

audio::-webkit-media-controls-play-button {
  display: none !important;
}

audio::-webkit-media-controls-start-playback-button {
  display: none !important;
}
#drumsIcon {
  height: auto;
  width: 100%;
}

.zoom-in-out-box {
  animation: zoom-in-zoom-out 1s ease 3;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1, 1);
  }
}
